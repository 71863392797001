import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { UserInfo } from "@api/api";

export const useUserProfile = () => {
  const { keycloak } = useKeycloak();
  const [userProfile, setUserProfile] = useState<UserInfo | undefined>();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await keycloak.loadUserInfo();
        setUserProfile(profile as UserInfo);
      } catch (err) {
        console.error("Failed to fetch user profile:", err);
      }
    };

    if (keycloak.authenticated) {
      fetchUserProfile();
    }
  }, [keycloak]); // Runs only when the keycloak instance changes, and if authenticated is true

  return userProfile;
};