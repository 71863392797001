import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Outlet } from "react-router-dom";

const RequireAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading authentication...</div>;
  }

  if (!keycloak.authenticated) {
    const hasRegisterFragment = window.location.hash.includes('register');

    if (hasRegisterFragment) {
      keycloak.register({ redirectUri: window.location.origin + window.location.pathname });
    } else {
      keycloak.login();
    }
    return <div>Redirecting...</div>;
  }

  return <Outlet />;
};

export default RequireAuth;
