import Keycloak from "keycloak-js";

/**
 * Initializes the Keycloak instance with configuration from environment variables.
 *
 * @constant {Keycloak} keycloak - The Keycloak instance used for authentication.
 *
 * Environment variables required:
 * - `REACT_APP_KEYCLOAK_ISSUER`: The Keycloak server URL.
 * - `REACT_APP_KEYCLOAK_REALM`: The realm name in Keycloak.
 * - `REACT_APP_KEYCLOAK_CLIENT_ID`: The client ID registered in the Keycloak realm.
 *
 * @see {@link https://www.keycloak.org/securing-apps/javascript-adapter Keycloak Documentation}
 */
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!
});

export default keycloak;
