import React, { Suspense, lazy } from "react";
import keycloak from "./Keycloak";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { StepsContextProvider } from "@contexts/steps-context/steps-context";
import "./App.css";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import * as Sentry from '@sentry/react';
import RequireAuth from "./utilities/require-auth";

const BillingInformation = lazy(
  () =>
    import(
      "@components/steps-section/billing-information-section/billing-information"
    )
);

const Contract = lazy(
  () => import("@components/steps-section/contract-section/contract")
);

const DomainName = lazy(
  () => import("@components/steps-section/domain-name-section/domain-name")
);

const JoinTheClub = lazy(
  () => import("@components/join-the-club-section/join-the-club")
);

const LayoutForSteps = lazy(
  () => import("@components/steps-section/layout-for-steps")
);

const SearchYourBusiness = lazy(
  () =>
    import(
      "@components/steps-section/search-your-business-section/search-your-business"
    )
);

const SocialMedia = lazy(
  () => import("@components/steps-section/social-media-section/social-media")
);

const Subscription = lazy(
  () => import("@components/steps-section/subscription-section/subscription")
);

const Summary = lazy(
  () => import("@components/steps-section/summary-section/summary")
);

const WelcomeOnBoard = lazy(
  () => import("@components/welcome-on-board-section/welcome-on-board")
);

function App() {
  const keycloakInitOptions = {
    onLoad: "check-sso",
    checkLoginIframe: false,
  };

  const LoadingIcon = () => (
      <div className="spinner w-8 h-8 border-4 border-green-600 border-t-transparent rounded-full animate-spin"></div>
  );

  keycloak.onTokenExpired = () => {
    console.log("Token expired, attempting to refresh...");
    keycloak.updateToken(30).catch(() => {
      console.log("Failed to refresh token, redirecting to login...");
      keycloak.login();
    });
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakInitOptions}
        LoadingComponent={
          <Suspense fallback={
            <div className="flex justify-center items-center h-screen">
              <LoadingIcon />
            </div>
          }>
            <div className="flex justify-center items-center h-screen">
              <LoadingIcon />
            </div>
          </Suspense>
        }
      >
        <Router>
          <StepsContextProvider>
            <Routes>
              {/* Public route - outside of Keycloak protection */}
              <Route path="/" element={<JoinTheClub />} />

              {/* Protected routes - will require authentication */}
              <Route element={<RequireAuth />}>
                <Route element={<LayoutForSteps />}>
                  <Route
                    path="/search-your-business"
                    element={<SearchYourBusiness />}
                  />
                  <Route path="/social-media" element={<SocialMedia />} />
                  <Route path="/domain-name" element={<DomainName />} />
                  <Route path="/subscription" element={<Subscription />} />
                  <Route path="/summary" element={<Summary />} />
                  <Route
                    path="/billing-information"
                    element={<BillingInformation />}
                  />
                  <Route path="/contract" element={<Contract />} />
                </Route>
                <Route path="/welcome-on-board" element={<WelcomeOnBoard />} />
              </Route>
            </Routes>
          </StepsContextProvider>
        </Router>
      </ReactKeycloakProvider>
    </Sentry.ErrorBoundary>
  );
}

const ErrorFallback = () => {
  return (
    <div className="error-boundary">
      <h1>Something went wrong</h1>
      <p>We're working on fixing the issue.</p>
    </div>
  );
};

export default App;
