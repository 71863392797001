import keycloak from "../Keycloak";
import { PostOrderData, Restaurant } from "./types";
import axios, { AxiosRequestConfig } from "axios";

/**
 * Asynchronously refreshes the authentication token by invoking the updateToken method on the Keycloak instance.
 *
 * @returns {Promise<string | undefined>} A promise that resolves to the refreshed authentication token if successful,
 * or undefined if the token could not be retrieved.
 */
const refreshAuthToken = async (): Promise<string | undefined> => {
  await keycloak.updateToken();

  return keycloak.token;
};

/**
 * Creates an Axios instance for the business API.
 */
export const businessClient = axios.create({
  baseURL: process.env.REACT_APP_BUSINESS_API_URL,
});

/**
 * Creates an Axios instance for the catalog API.
 */
const catalogClient = axios.create({
  baseURL:  process.env.REACT_APP_CATALOG_API_URL,
});


catalogClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
          error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        console.error("Unauthorized request. Attempting to refresh token...");
        originalRequest._retry = true;

        try {
          const newAuthToken = await refreshAuthToken();

          if (!newAuthToken) {
            throw new Error("Token refresh failed");
          }

          // Update the authorization header with the new token
          if (!originalRequest.headers) {
            originalRequest.headers = {};
          }

          originalRequest.headers["Authorization"] = `Bearer ${newAuthToken}`;

          // Retry the original request with the new token
          return catalogClient.request(originalRequest);
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
);


/**
 * Interceptor for handling 401 responses from the business API.
 *
 * This interceptor attempts to refresh the authentication token and retry the original request.
 */
businessClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
      error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      console.error("Unauthorized request. Attempting to refresh token...");
      originalRequest._retry = true;

      try {
        const newAuthToken = await refreshAuthToken();

        if (!newAuthToken) {
          throw new Error("Token refresh failed");
        }

        // Update the authorization header with the new token
        if (!originalRequest.headers) {
          originalRequest.headers = {};
        }

        originalRequest.headers["Authorization"] = `Bearer ${newAuthToken}`;

        // Retry the original request with the new token
        return businessClient.request(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

/**
 * Interceptor for handling 401 responses from the catalog API.
 *
 * This interceptor attempts to refresh the authentication token and retry the original request.
 */
const orderClient = axios.create({
  baseURL: process.env.REACT_APP_TRANSACTION_API_URL,
});


orderClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
          error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        console.error("Unauthorized request. Attempting to refresh token...");
        originalRequest._retry = true;

        try {
          const newAuthToken = await refreshAuthToken();

          if (!newAuthToken) {
            throw new Error("Token refresh failed");
          }

          // Update the authorization header with the new token
          if (!originalRequest.headers) {
            originalRequest.headers = {};
          }

          originalRequest.headers["Authorization"] = `Bearer ${newAuthToken}`;

          // Retry the original request with the new token
          return orderClient.request(originalRequest);
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
);


/**
 * Sends a post request to create a new order.
 *
 * @param {PostOrderData} data - The data payload for the order.
 * @param {null | string} authToken - The authentication token for the request. Can be null or a valid string.
 * @return {Promise<Order>} A promise that resolves to the created order object.
 */
export async function postOrder(
  data: PostOrderData,
  authToken: null | string
): Promise<Order> {
  try {
    const response = await orderClient.post("/order", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      },
    });

    return response.data as Order;
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Initializes a new order.
 *
 * @param {string} authToken - The authentication token to be used for the request.
 * @return {Promise<Order>} A promise that resolves to the initialized order object.
 */
export async function initializeOrder(authToken: string): Promise<Order> {
  try {
    const response = await businessClient.post("/orders/initialize", {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data as Order;
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Performs a search for domains based on the given domain name.
 *
 * @param {string} domainName - The domain name to search for.
 * @param {string | null} authToken - The authentication token to be used for the request. Optional,
 * allows for secure access if required.
 * @return {Promise<string[]>} - A promise that resolves to an array of domain names matching the search criteria.
 */
export async function searchDomains(
  domainName: string,
  authToken: string | null
): Promise<string[]> {

  try {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (authToken) {
      headers["Authorization"] = `Bearer ${authToken}`;
    }

    const response = await businessClient.get(
        `/domain/search-domains?domain=${domainName}`,
        { headers }
    );
    return response.data.domains;
  } catch (error) {
    console.error("Error searching domains:", error);
    return [];
  }
}


/**
 * Fetches the contract PDF associated with the given contract ID.
 *
 * @param {string} id - The unique identifier of the contract.
 * @param {string} authToken - The authorization token required for API access.
 * @return {Promise<Blob>} A promise that resolves to the contract PDF as a Blob.
 */
export async function fetchContractPdf(
  id: string,
  authToken: string
): Promise<Blob> {

  try {
    const response = await businessClient.get(
        `/orders/contract-pdf/${id}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `Bearer ${authToken}`,
          },
        }
    );

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}


/**
 * Represents a contract with specific attributes and preferences.
 *
 * This interface defines the structure for a contract object, including its unique identifier,
 * name, content, and associated preferences. Contracts are typically used to formalize agreements
 * and may include multiple sections or clauses.
 *
 * Properties:
 * - `_id` (string): The unique identifier for the contract.
 * - `name` (string): The name of the contract.
 * - `content` (string[]): An array of strings representing the content or clauses of the contract.
 * - `preferences` (string[]): An array of strings representing the preferences or
 * additional details related to the contract.
 */
export interface Contract {
  _id: string;
  name: string;
  content: string[];
  preferences: string[];
}


/**
 * Fetches a contract associated with a given order ID.
 *
 * @param {string} orderId - The unique identifier of the order for which the contract is being retrieved.
 * @param {string} authToken - The authorization token used for authentication in the API request.
 * @returns {Promise<Contract>} A promise that resolves to the contract object retrieved for the specified order ID.
 * @throws Will reject the promise with an error if the API request fails.
 */
export const fetchContractByOrderId = async (
  orderId: string,
  authToken: string
): Promise<Contract> => {
  try {
    const response = await businessClient.get(
        `/orders/${orderId}/contract`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
    );

    return response.data;
  } catch (err) {
    console.error("Error fetching contract:", err);
    return Promise.reject(err);
  }
};


/**
 * Represents an order entity within the system.
 *
 * This interface defines the structure of an order, containing information
 * about the customer, the associated company details, location data, and optional
 * metadata.
 *
 * Properties:
 * - `_id`: A unique identifier for the order.
 * - `name`: The first name of the customer placing the order.
 * - `lastName`: The last name of the customer placing the order.
 * - `companyName`: The name of the company associated with the order.
 * - `domainName`: The domain name linked to the company.
 * - `taxId`: The tax identification number related to the company.
 * - `companyCode`: A unique code representing the company.
 * - `country`: The country of the customer or company.
 * - `province`: The province or region in which the company or customer resides.
 * - `destinationCode`: A code representing the delivery or destination point.
 * - `city`: The name of the city associated with the order.
 * - `cap`: The postal code or ZIP code of the delivery location.
 * - `address`: The street address associated with the customer or order.
 * - `targetPec`: The certified email address (PEC) for notifications or confirmations.
 * - `plan`: An optional property representing the current plan associated with this order.
 * - `packages`: An optional array specifying any package identifiers tied to the order.
 * - `discountCode`: An optional property defining any applicable discount code.
 * - `planType`: An optional property specifying the type of plan chosen by the customer.
 * - `metadata`: An optional object containing additional contextual information associated with the order.
 *    - `restaurantInfo`: A record with detailed information relevant to restaurants, if applicable.
 *    - `registrationStep`: Represents the specific step reached in the registration or ordering process.
 */
export interface Order {
  _id: string;
  name: string;
  lastName: string;
  companyName: string;
  domainName: string;
  taxId: string;
  companyCode: string;
  country: string;
  province: string;
  destinationCode: string;
  city: string;
  cap: string;
  address: string;
  targetPec: string;
  plan?: string;
  packages?: string[];
  discountCode?: string;
  planType?: string;

  metadata?: {
    restaurantInfo: Record<string, any>;
    registrationStep: string;
  };
}

/**
 * Fetches an order by its unique identifier.
 *
 * @param {string} orderId - The unique identifier of the order to retrieve.
 * @param {string} authToken - The authentication token used for the API request.
 * @returns {Promise<Order>} A promise that resolves to the order object associated with the specified ID.
 * @throws Will reject the promise with an error if the API request fails.
 */
export const getOrderById = async (
  orderId: string,
  authToken: string
): Promise<Order> => {
  try {
    const { data } = await businessClient.get(`/orders/${orderId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return data as Order;
  } catch (err) {
    console.error("Error fetching order:", err);
    return Promise.reject(err);
  }
};


/**
 * Retrieves the current order for an authenticated user.
 *
 * This asynchronous function communicates with the business client API to fetch the current order
 * associated with the provided authentication token. If the request is successful, the order
 * details are returned as an Order object. If the request fails, or if there is an error during
 * the operation, the function returns null.
 *
 * @param {string} authToken - The authentication token required for accessing the current order.
 * @returns {Promise<Order|null>} A promise that resolves to the current Order object if successful,
 * or null if an error occurs or no order is found.
 *
 * @throws {Error} Throws an error if the API call fails outside of expected conditions.
 */
export const getCurrentOrder = async (
  authToken: string
): Promise<Order | null> => {
  try {
    const { data } = await businessClient.get(`/orders`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return data as Order;
  } catch (err) {
    return null;
  }
};

/**
 * Updates an existing order with the specified patch object.
 *
 * This function sends a PATCH request to the business client API to update the order
 * with the provided order ID and patch object. The authentication token is used to authorize
 * the request, and an optional AbortSignal can be provided to cancel the operation if needed.
 *
 * @param {string} orderId - The unique identifier of the order to update.
 * @param {Record<string, any>} patchObj - The object containing the fields to update in the order.
 * @param {string} authToken - The authentication token required for the API request.
 * @param {AbortSignal} [signal] - An optional AbortSignal to cancel the operation.
 * @returns {Promise<Order>} A promise that resolves to the updated order object if successful.
 *
 * @throws {Error} Throws an error if the API call fails outside of expected conditions.
 */
export const patchOrder = async (
  orderId: string,
  patchObj: Record<string, any>,
  authToken: string,
  signal?: AbortSignal
): Promise<Order> => {

  try {
    const { data } = await businessClient.patch(
      `/orders`,
      {
        id: orderId,
        ...patchObj,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        signal,
      }
    );

    return data as Order;
  } catch (err) {
    console.error("Error updating order:", err);
    return Promise.reject(err);
  }
};

/**
 * Represents a subscription or service plan.
 *
 * This interface defines the structure for a plan with its associated
 * properties such as identification, pricing, description, and other
 * details.
 *
 * Properties:
 * - `_id`: A unique identifier for the plan.
 * - `name`: The name of the plan.
 * - `monthlyPrice`: The cost of the plan on a monthly basis.
 * - `annualPrice`: The cost of the plan on an annual basis.
 * - `currency`: The currency in which the prices are denominated.
 * - `description`: A textual description providing details about the plan.
 * - `isDefault`: Indicates whether this plan is the default or recommended
 *   choice.
 * - `advertisingText`: A short, promotional slogan or phrase associated with
 *   the plan.
 * - `includedText`: Additional explanatory or informational text about what
 *   is included in the plan.
 */
export interface Plan {
  _id: string;
  name: string;
  monthlyPrice: number;
  annualPrice: number;
  currency: string;
  description: string;
  isDefault: boolean;
  advertisingText: string;
  includedText: string;
}


/**
 * Fetches a list of plans from the server.
 *
 * This asynchronous function retrieves plan details by making a GET request
 * to the "/plans" endpoint of the business client API. The request requires
 * an authorization token to be passed in the headers for authentication.
 *
 * @param {string} authToken - The authorization token used to authenticate the request.
 * @returns {Promise<Plan[]>} A promise that resolves to an array of Plan objects.
 * @throws Will throw an error if the request fails or if an invalid response is received.
 */
export const getPlans = async (authToken: string): Promise<Plan[]> => {
  const { data } = await businessClient.get("/plans", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return data as Plan[];
};


/**
 * Represents a subscription package with pricing and details.
 *
 * @interface Package
 * @property {string} _id - Unique identifier for the package.
 * @property {string} name - Name of the package.
 * @property {number} monthlyPrice - Price of the package on a monthly basis.
 * @property {number} annualPrice - Price of the package on an annual basis.
 * @property {string} currency - Currency in which the prices are defined.
 * @property {string[]} includedText - List of features or details included in the package.
 */
export interface Package {
  _id: string;
  name: string;
  monthlyPrice: number;
  annualPrice: number;
  currency: string;
  includedText: string[];
}


/**
 * Represents a product with details such as ID, name, price, tax behavior, and associated tax rates.
 *
 * @interface Product
 *
 * @property {string} id - The unique identifier for the product.
 * @property {string} name - The name of the product.
 * @property {number} price - The price of the product in the smallest currency unit.
 * @property {'exclusive' | 'inclusive'} tax_behavior - Indicates whether the product's price includes taxes ('inclusive') or taxes are added on top ('exclusive').
 * @property {string[]} tax_rates - A list of tax rate IDs applicable to the product.
 */
export interface Product {
  id: string;
  name: string;
  price: number;
  tax_behavior: 'exclusive' | 'inclusive';
  tax_rates: string[];
}

/**
 * Fetches a list of products by making an authenticated request to the business API.
 *
 * This function requires a valid authentication token to access the list of available products.
 * It performs an HTTP GET request to the "/products" endpoint of the business client.
 *
 * @param {string} authToken - Bearer token used for authentication in the API request.
 * @returns {Promise<Product[]>} A promise resolving to an array of Product objects retrieved from the API.
 * @throws {Error} If the request fails or if the authentication token is invalid.
 */
export const getProducts = async (authToken: string): Promise<Product[]> => {
    const { data } = await businessClient.get("/products", {
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
        },
    });

    return data as Product[];
}


/**
 * Fetches the list of packages from the business client API.
 *
 * This asynchronous function retrieves package data by making a GET request
 * to the "/packages" endpoint of the business client. The request includes
 * an authorization token in the headers for authentication.
 *
 * @param {string} authToken - The authentication token to include in the request headers.
 * @returns {Promise<Package[]>} A promise that resolves to an array of Package objects.
 * @throws {Error} If the API request fails or returns an unexpected response.
 */
export const getPackages = async (authToken: string): Promise<Package[]> => {
  const { data } = await businessClient.get("/packages", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return data as Package[];
};



/**
 * Represents a discount code with associated information.
 *
 * This interface defines the structure for discount-related data used in applications.
 * Each discount code includes a unique identifier, the code itself, a descriptive label,
 * and the discount value it provides.
 *
 * Properties:
 * - `_id`: The unique identifier for the discount code.
 * - `code`: The actual discount code string.
 * - `label`: A descriptive label for the discount code.
 * - `discount`: The discount value provided by this code, typically represented as a percentage or fixed amount.
 */
export interface DiscountCode {
  _id: string;
  code: string;
  label: string;
  discount: number;
}


/**
 * Asynchronously retrieves a discount code from the business client.
 *
 * This function fetches the details of a discount code identified by the
 * provided code string, using the specified authentication token for access.
 * The discount code details will be returned in the form of a `DiscountCode` object.
 *
 * @param code - The unique identifier of the discount code to retrieve.
 * @param authToken - The authorization token required to authenticate the request.
 * @returns A Promise that resolves to a `DiscountCode` object containing the details of the discount code.
 * @throws An error if the request fails or the discount code cannot be retrieved.
 */
export const getDiscountCode = async (
  code: string,
  authToken: string
): Promise<DiscountCode> => {
  const { data } = await businessClient.get(`/discount-codes/${code}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return data as DiscountCode;
};


/**
 * Represents a user's information.
 *
 * The `UserInfo` interface provides structure for handling identity and profile-related details
 * for a user. This structure includes common fields such as email, name components, and locale
 * among others, typically used in authentication and user management systems.
 *
 * Properties:
 * - `email`: The email address of the user.
 * - `email_verified`: A boolean indicating if the user's email has been verified.
 * - `family_name`: The user's last name or family name.
 * - `given_name`: The user's first name or given name.
 * - `locale`: The locale identifier preferred by the user.
 * - `name`: The full name of the user, including first and last names.
 * - `preferred_username`: The preferred username for the user.
 * - `sub`: A unique identifier for the user, typically provided by an authentication provider.
 */
export interface UserInfo {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sub: string;
}


/**
 * Enum representing supported payment providers.
 * Use this enum to specify the payment provider for processing transactions.
 */
export enum EPaymentProvider {
  STRIPE = "STRIPE",
}


/**
 * Enum representing the various payment methods available.
 *
 * EPaymentMethod provides a standardized set of values to represent
 * different payment methods that can be used in applications. This
 * ensures consistency and prevents errors related to invalid payment
 * method inputs.
 *
 * Members:
 * - CARD: Represents payment made using a card (e.g., credit or debit).
 */
export enum EPaymentMethod {
  CARD = "CARD",
}


/**
 * Enum representing the status of an electronic payment.
 *
 * This enum contains the possible states for a payment in a digital transaction.
 *
 * Enum members:
 * - `PENDING`: Indicates that the payment is currently pending and has not been completed yet.
 */
export enum EPaymentStatus {
  PENDING = "PENDING",
}

/**
 * Enum representing different sources of content or data within the application.
 *
 **/
export enum ESource {
  WEB = "web",
}

/**
 * Enum representing common recurrence patterns in CRON format.
 *
 * The `ERecurrency` enum provides predefined CRON expressions
 * to specify recurring events based on different time intervals.
 *
 * Each value in the enum is a string that corresponds to a CRON expression.
 *
 * Enum Members:
 * - `EVERY_WEEK`: Represents a recurrence every week, occurring on Sunday at midnight.
 * - `EVERY_WEEKDAY`: Represents a recurrence every weekday, occurring Monday to Friday at midnight.
 * - `EVERY_WEEKEND`: Represents a recurrence for every weekend, occurring on Saturday and Sunday at midnight.
 * - `EVERY_MONTH`: Represents a recurrence every month, occurring on the 1st of each month at midnight.
 * - `EVERY_QUARTER`: Represents a recurrence every quarter, occurring on the 1st day of every three months at midnight.
 * - `EVERY_6_MONTHS`: Represents a recurrence every 6 months, occurring on the 1st day of every six months at midnight.
 * - `EVERY_YEAR`: Represents a recurrence every year, occurring on the 1st day of January at midnight.
 */
export enum ERecurrency {
  EVERY_WEEK = "0 0 * * 0",
  EVERY_WEEKDAY = "0 0 * * 1-5",
  EVERY_WEEKEND = "0 0 * * 6,0",
  EVERY_MONTH = "0 0 1 */1 *",
  EVERY_QUARTER = "0 0 1 */3 *",
  EVERY_6_MONTHS = "0 0 1 */6 *",
  EVERY_YEAR = "0 0 1 0 *",
}

/**
 * Defines the type `SupportedCurrency` which represents the set of currency codes supported.
 * It is a union type of string literals, specifically "eur" and "usd".
 *
 * This type is typically used to ensure that only the supported currency codes are allowed
 * in operations or configurations where a currency code is required.
 *
 * Values:
 * - "eur": Represents the Euro currency.
 * - "usd": Represents the United States Dollar currency.
 */
export type SupportedCurrency = "eur" | "usd";

/**
 * The BillingInformation interface represents the structure of data required
 * to store or process billing details for an individual or organization.
 *
 * Properties:
 * - `name`: The first name of the individual associated with the billing.
 * - `lastName`: The last name of the individual associated with the billing.
 * - `address`: The full billing address including street information.
 * - `city`: The city where the billing address is located.
 * - `country`: The country of the billing address.
 * - `postalCode`: The postal code or ZIP code for the billing address.
 * - `companyName`: The name of the company if billing is for an organization.
 * - `taxId`: The tax identification number for the individual or company.
 * - `email`: The email address associated with the billing contact.
 */
export interface BillingInformation {
  name: string;
  lastName: string;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  companyName: string;
  taxId: string;
  email: string;
}

/**
 * Represents a payment platform used for processing transactions.
 *
 * This interface defines the essential properties related to payment providers,
 * which can be used to identify and interact with a specific payment platform.
 *
 * Properties:
 * - `provider`: The name of the payment platform or service provider.
 * - `externalId`: An identifier corresponding to the payment platform, typically
 *   used to reference a unique entity or connection within that system.
 */
export interface PaymentPlatform {
  provider: string;
  externalId: string;
}

/**
 * Represents the request payload for creating a payment session.
 *
 * This interface is used to define the structure of the data required
 * to initiate a payment session with specific parameters such as
 * payment provider, method, billing details, and transaction details.
 *
 * Properties:
 *
 * @property {string} name - The name associated with the payment session.
 * @property {EPaymentProvider} provider - The payment provider to be used for the session.
 * @property {EPaymentMethod} method - The payment method selected for the transaction.
 * @property {ESource} source - The source of the payment request.
 * @property {ERecurrency} recurrency - The recurrence type, indicating whether the payment is one-time or recurring.
 * @property {SupportedCurrency} currency - The currency in which the transaction will be performed.
 * @property {Array<{id: string, name: string, quantity: number, price: number}>} items -
 * The list of items included in the payment, each with an id, name, quantity, and price.
 * @property {BillingInformation} billingInformation - The billing details associated with the transaction.
 * @property {{paymentPlatforms: PaymentPlatform[]}} offer - Offer details, specifying supported payment platforms.
 * @property {number} total - The total amount to be charged for the transaction.
 * @property {EPaymentStatus} status - The current status of the payment session.
 * @property {string} email - The email address linked to the payment session.
 */
export interface CreatePaymentSessionRequest {
  name: string;
  provider: EPaymentProvider;
  method: EPaymentMethod;
  source: ESource;
  recurrency: ERecurrency;
  currency: SupportedCurrency;
  items: Array<{
    id: string;
    name: string;
    quantity: number;
    price: number;
  }>;
  billingInformation: BillingInformation;
  offer: {
    paymentPlatforms: PaymentPlatform[];
  };
  total: number;
  status: EPaymentStatus;
  email: string;
}


/**
 * Asynchronously creates a payment session by sending the provided data to the server.
 *
 * This function sends a POST request to the "/payment/create-session" endpoint with the specified
 * data and required headers. On successful execution, it returns an object containing the URL
 * for the created payment session. If an error occurs during the request, it logs the error
 * and rethrows it.
 *
 * @param {CreatePaymentSessionRequest} data - The request data required to create a payment session.
 * @returns {Promise<{url: string}>} A promise that resolves with an object containing the payment session URL.
 * @throws Will throw an error if the request fails.
 */
export const createPaymentSession = async (
  data: CreatePaymentSessionRequest
): Promise<{ url: string }> => {
  try {
    const response = await orderClient.post("/payment/create-session", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Payment session creation failed:", error);
    throw error;
  }
};


/**
 * Fetches business information associated with a specific tax ID.
 * Makes an asynchronous request to retrieve company details.
 *
 * @param {string} taxId - The tax identification number of the business.
 * @param {string} authToken - The authorization token used for API authentication.
 * @returns {Promise<Record<string, any>>} A promise resolving to the company information as an object.
 * Returns an empty object if an error occurs.
 *
 * @throws {Error} Logs an error to the console in case the API request fails.
 */
export const getInfoByTaxId = async (
  taxId: string,
  authToken: string
): Promise<Record<string, any>> => {
  try {
    const response = await businessClient.get(
      `/open-api/company-info/${taxId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching tax id info:", error);
    return {};
  }
};


/**
 * Represents a payment request to process a transaction through an integrated provider.
 * The payment request contains details about the items being purchased, payment information, and additional metadata.
 *
 * Properties:
 * - `provider`: The payment provider being used for the transaction (e.g., Stripe).
 * - `items`: An array of objects containing details about the items being purchased, including item ID, quantity, and name.
 * - `recurrency` (optional): Indicates the recurrence type of the payment, if applicable.
 * - `offer` (optional): Contains information about available offers on payment platforms, including provider details and an external ID.
 * - `externalPaymentId`: An external identifier for the payment, to link the request with an external system.
 * - `billingInformation` (optional): Billing details of the customer making the payment.
 * - `source`: Indicates the source of the payment request, such as the origin or identifier of the application initiating the transaction.
 * - `successUrl` (optional): A URL that the user will be redirected to after successfully completing the payment process.
 * - `cancelUrl` (optional): A URL that the user will be redirected to if they cancel the payment process.
 * - `orderId`: A unique identifier for the order associated with this payment request.
 */
interface PaymentRequest {
  provider: "STRIPE";
  items: Product[];
  recurrency?: ERecurrency;
  offer?: {
    paymentPlatforms: Array<{
      provider: string;
      externalId: string;
    }>;
  };
  externalPaymentId: string;
  billingInformation?: BillingInformation;
  source: string;
  successUrl?: string;
  cancelUrl?: string;
  orderId: string;
}

/**
 * Creates a payment request and redirects the user to the payment provider's checkout page.
 *
 * @async
 * @function
 * @param {PaymentRequest} paymentData - The payment details required for processing the payment.
 * @param {string} authToken - The authorization token for authenticating the request.
 * @throws {Error} Throws an error if the payment creation fails or if the response lacks a redirect URL.
 */
export const createPayment = async (
  paymentData: PaymentRequest,
  authToken: string
) => {
  try {
    const response = await orderClient.post("/payment", paymentData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.data.url) {
      throw new Error("Payment creation failed");
    }

    window.location.href = response.data.url; // Redirect to Stripe checkout
  } catch (error) {
    console.error("Payment creation failed:", error);
    throw error;
  }
};

/**
 * Retrieves the current restaurant information associated with the provided authorization token.
 *
 * @param {string} authToken - The authorization token required to authenticate the request.
 * @returns {Promise<Restaurant>} A promise that resolves to the restaurant data.
 */
export const getCurrentRestaurant = async (
  authToken: string | undefined
): Promise<Restaurant> => {
  const { data } = await catalogClient.get("/restaurant", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return data as Restaurant;
};
